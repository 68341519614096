export const trainingLanguages = [
  {
    transKey: "container.languages.english",
    value: "english"
  },
  {
    transKey: "container.languages.german",
    value: "german"
  },
  {
    transKey: "container.languages.french",
    value: "french"
  },
  {
    transKey: "container.languages.italian",
    value: "italian"
  },
  {
    transKey: "container.languages.spanish",
    value: "spanish"
  },
  {
    transKey: "container.languages.greek",
    value: "greek"
  }
];

export const validityOptions = [
  {
    transKey: "trg.validity_field.no_expiry",
    value: "no_expiry",
    no_expiry: true
  },
  {
    transKey: "trg.validity_field.day_number",
    value: "day_number"
  },
  {
    transKey: "trg.validity_field.year_number",
    value: "year_number"
  },
  {
    transKey: "trg.validity_field.years_minus_one_day",
    value: "years_minus_one_day"
  },
  {
    transKey: "trg.validity_field.current_year",
    value: "current_year"
  },
  {
    transKey: "trg.validity_field.next_year",
    value: "next_year"
  },
  {
    transKey: "trg.validity_field.december_in_x_years",
    value: "december_in_x_years"
  },
  {
    transKey: "trg.validity_field.specific_date",
    value: "specific_date"
  }
];
