import React from "react";
import { FILTER_TYPE_STRING } from "../../../components/SmartTable/methods";
import TableCell from "../../../components/SmartTable/TableCell";
import { formatString } from "../../../utils/common";
import UserTableCard from "../../Core/Users/UserTableCard";
import { PERMISSION_MANAGE_QAM_ENTITIES } from "../../../components/_auth/permissions/permissions";
import ColumnActiveSwitch from "../../Organization/OrgSettings/Functions/ColumnActiveSwitch";
import Tooltip from "../../../components/Tooltip";
import { openModalAction } from "../../../components/Modal/reducer";
import DeleteItemButton from "../../../components/SmartTable/TableFiltersActions/DeleteItemButton";
import EntitiesContactColumn from "./EntitiesContactColumn";
import { MODAL_QAM_ENTITY } from "./index";

export const TABLE_QAM_ENTITIES = "table-qam-entities";

export const generateColumns = (permission, dispatch, trans) => {
  return [
    {
      title: "qam.entities.entity",
      dataIndex: "name",
      sorter: true,
      filterType: FILTER_TYPE_STRING,
      width: 200,
      render: (text, row) => (
        <TableCell>
          <UserTableCard
            minWidth={200}
            name={row.name}
            image={row.files[0]?.sizes?.users?.tableImage}
            desc={row.number}
          />
        </TableCell>
      )
    },
    {
      title: "qam.entities.city",
      dataIndex: "city",
      sorter: true,
      filterType: FILTER_TYPE_STRING,
      width: 150,
      render: (text, row) => <TableCell minWidth={150}>{formatString(row.city, "-")}</TableCell>
    },
    {
      title: "qam.entities.address",
      dataIndex: "address",
      filterType: FILTER_TYPE_STRING,
      sorter: true,
      width: 250,
      render: (text, row) => {
        let result = row.address;
        if (result) {
          result = row.address2 ? `${result}, ${row.address2}` : result;
        }
        return <TableCell>{formatString(result, "-")}</TableCell>;
      }
    },
    {
      title: "qam.entities.contact",
      sorter: true,
      dataIndex: "url",
      filterNameKey: "url",
      filterType: FILTER_TYPE_STRING,
      width: 200,
      render: (text, row) => (
        <TableCell minWidth={80}>
          <EntitiesContactColumn row={row} />
        </TableCell>
      )
    },
    ...(permission.canDo(PERMISSION_MANAGE_QAM_ENTITIES)
      ? [
          {
            title: trans("container.general_words.actions"),
            fixed: "right",
            width: 100,
            render: (row = {}) => (
              <div className="table-actions-column">
                <ColumnActiveSwitch
                  className="mr-15"
                  isActive={row.active}
                  route={`qam/entities/change-state/${row.id}`}
                  tooltip={{
                    active: "qam.entities.undo_activate_tooltip",
                    inactive: "qam.entities.activate_tooltip"
                  }}
                />
                <Tooltip title={trans("container.general_words.edit")} placement="top" className="text-primary">
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(openModalAction({ name: MODAL_QAM_ENTITY, data: row }));
                    }}
                  >
                    <i className="ti-pencil-alt" />
                  </a>
                </Tooltip>
                <DeleteItemButton
                  disabled={!row.canBeDeleted}
                  tableName={TABLE_QAM_ENTITIES}
                  route={`qam/entities/${row.id}`}
                  content={
                    <div>
                      <div>{trans("container.confirm_modal.form_subtitle")}</div>
                    </div>
                  }
                />
              </div>
            )
          }
        ]
      : [])
  ];
};
