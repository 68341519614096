import React from "react";
import {
  FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_SELECT_MODE_2,
  FILTER_TYPE_SELECT_MODE_3,
  FILTER_TYPE_STRING
} from "../../../components/SmartTable/methods";
import TableCell from "../../../components/SmartTable/TableCell";
import Tag from "../../../components/Tag";
import { formatString } from "../../../utils/common";
import ShowMoreList from "../../../components/ShowMoreList";
import { closeModalAction, openModalAction } from "../../../components/Modal/reducer";
import { MODAL_EXPORT_LOADING } from "../../../components/SmartTable/ExportDropdown/LoadingModal";
import axios from "../../../axios";
import { downloadFile } from "../../../utils/downloadFile";
import { openNotification } from "../../../components/Notification";
import { EXPORT_TYPE_PDF } from "../../../components/SmartTable/ExportDropdown/ExportOverlay";
import ActivityActionColumn from "./ActivityActionColumn";

export const TABLE_QAM_ACTIVITIES = "table-qam-activities";

export const generateColumns = (permission, loggedUser, language, trans, formatLocale, dateFormat) => {
  return [
    {
      title: "qam.activities.stage",
      dataIndex: "stage_name",
      sorter: true,
      width: 130,
      filterType: FILTER_TYPE_SELECT_MODE_2,
      filterProps: {
        apiRoute: "qam/stages",
        transformer: (stages) => {
          return [
            {
              text: trans("qam.activities.all_stages_without_archived"),
              transKey: "qam.activities.all_stages_without_archived",
              name: "__Closed",
              id: "__Closed",
              value: "__Closed"
            },
            ...stages.map((item) => {
              const stageTranslationKey = getActivityStageLangKeyName(item.name);
              return {
                ...item,
                id: item.name,
                value: item.name,
                text: trans(stageTranslationKey),
                transKey: stageTranslationKey
              };
            })
          ];
        }
      },
      render: (text, row) => {
        const stageLangKeyName = getActivityStageLangKeyName(row.stage_name);
        return (
          <TableCell>
            {stageLangKeyName ? (
              <Tag style={{ background: row.stage_color, color: "#fff", border: 0 }}>{trans(stageLangKeyName)}</Tag>
            ) : (
              "-"
            )}
          </TableCell>
        );
      }
    },
    {
      title: "qam.activities.id",
      dataIndex: "number",
      sorter: true,
      filterType: FILTER_TYPE_STRING,
      width: 130,
      render: (text, row) => <TableCell minWidth={130}>{formatString(row.number, "-")}</TableCell>
    },
    {
      title: "container.general_words.title",
      dataIndex: "title",
      sorter: true,
      filterType: FILTER_TYPE_STRING,
      render: (text, row) => <TableCell minWidth={130}>{formatString(row.title, "-")}</TableCell>
    },
    {
      title: "qam.activities.scope",
      dataIndex: "scope",
      filterType: FILTER_TYPE_SELECT_MODE_3,
      filterNameKey: "scopes",
      filterProps: {
        apiRoute: "qam/scopes?order_by_column%5B%5D=name&order_by_type%5B%5D=asc"
      },
      render: (text, row) => (
        <TableCell minWidth={130}>
          {row.scopes?.length > 0
            ? row.scopes.map((scope) => (
                <Tag key={scope.id} className="tag-secondary">
                  {scope.name}
                </Tag>
              ))
            : "-"}
        </TableCell>
      )
    },
    {
      title: "qam.activities.entity",
      dataIndex: "entity",
      sorter: true,
      filterType: FILTER_TYPE_STRING,
      render: (text, row) => (
        <TableCell minWidth={130}>
          {formatString(row.stage_name === "Closed" ? row?.entity_name : row?.entity, "-")}
        </TableCell>
      )
    },
    {
      title: "qam.activities.timeline",
      dataIndex: "timeline_date",
      sorter: true,
      filterType: FILTER_TYPE_DATE_RANGE,
      width: 170,
      render: (text, row) => {
        const formattedDates = row.auditdates.map((item) => {
          return formatLocale({ date: item.date, dateFormat: item.hour ? `${dateFormat} HH:mm` : dateFormat });
        });
        return (
          <TableCell minWidth={130}>
            <ShowMoreList data={formattedDates} />
          </TableCell>
        );
      }
    },
    {
      title: trans("container.general_words.actions"),
      fixed: "right",
      width: 70,
      render: (row = {}) => <ActivityActionColumn row={row} />
    }
  ];
};

export const downloadActivityReportPdf = (trans, dispatch, row = {}, exportType) => {
  dispatch(openModalAction({ name: MODAL_EXPORT_LOADING }));
  axios
    .get(`qam/audits/export/${row.id}`, {
      params: {
        exportType: exportType,
        filename: row.title,
        type: EXPORT_TYPE_PDF
      }
    })
    .then(
      (res) => {
        const fileName = res.data.data.url.substring(res.data.data.url.lastIndexOf("/") + 1, res.data.data.url.length);

        downloadFile({
          fileUrl: res.data.data.url,
          fileName: fileName,
          onSuccess: () => {
            dispatch(closeModalAction({ name: MODAL_EXPORT_LOADING }));
          },
          onFailed: () => {
            openNotification({
              type: "error",
              message: trans("container.form_errors.internal_server_error")
            });
            dispatch(closeModalAction({ name: MODAL_EXPORT_LOADING }));
          }
        });
      },
      () => {
        openNotification({
          type: "error",
          message: trans("container.form_errors.internal_server_error")
        });
        dispatch(closeModalAction({ name: MODAL_EXPORT_LOADING }));
      }
    );
};

export const getActivityStageLangKeyName = (name) => {
  let toReturn = "";
  switch (name) {
    case "Planning":
      toReturn = "qam.general_words.qam_activity_stages.planning";
      break;
    case "Preparation":
      toReturn = "qam.general_words.qam_activity_stages.preparation";
      break;
    case "Execution":
      toReturn = "qam.general_words.qam_activity_stages.execution";
      break;
    case "Reporting":
      toReturn = "qam.general_words.qam_activity_stages.reporting";
      break;
    case "Surveillance Finding":
      toReturn = "qam.general_words.qam_activity_stages.surveillance_finding";
      break;
    case "Closed":
      toReturn = "qam.general_words.qam_activity_stages.closed";
      break;
    default:
      toReturn = null;
      break;
  }
  return toReturn;
};

export const replaceLocalstorageFilters = (localstorageFilters, stageFilter) => {
  const stgFilters = stageFilter && [
    {
      column: "stage_name",
      op: "eq",
      id: stageFilter.id,
      value: stageFilter.value,
      name: stageFilter.name,
      text: stageFilter.name,
      transKey: stageFilter.transKey
    }
  ];

  return {
    ...(stageFilter ? { filters: stgFilters } : localstorageFilters)
  };
};
